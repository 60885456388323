export const contactConfig = {
	serviceID: 'gmail',
	templateID: 'logicbuilds_contact_form',
	form: '#logicbuilds-contact-form',
	userID: 'user_1LLTvH7XBysze9p0RumwL',
};

export const proposalConfig = {
	serviceID: 'gmail',
	templateID: 'logicbuilds_proposal_form',
	form: '#logicbuilds-proposal-form',
	userID: 'user_1LLTvH7XBysze9p0RumwL',
};