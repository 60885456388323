import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Footer } from '../Footer'
import { routes } from '../../../config'

interface IProps extends React.Props<any> {
  pageInfo?: {
    pageTitle: string
    pageDescription: string
    pagePath: string
  }
  children?: any
  match?: {
    children: Element
    path: string
  }
}
interface IState {
  pageTitle: string
  pageDescription: string
  pagePath: string
}

export class Layout extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    const { match, pageInfo } = props
    if (pageInfo) {
      const { pageTitle, pageDescription, pagePath } = pageInfo
      if (pageTitle && pageDescription && pagePath) {
        this.state = {
          pageTitle,
          pageDescription,
          pagePath,
        }
        return
      }
    } else {
      let currentRoute = null
      if (match && match.path) {
        currentRoute = routes.find((route) => route.path === match.path)
        if (currentRoute && currentRoute.path) {
          this.state = {
            pageTitle: currentRoute.title,
            pageDescription: currentRoute.description,
            pagePath: window.location.href,
          }
        }
      }
    }
  }

  render() {
    return (
      <div className="layout">
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <link rel="canonical" href={this.state.pagePath} />
        </Helmet>
        <div className="content animated faster fadeIn">{this.props.children}</div>
        <Footer />
      </div>
    )
  }
}
