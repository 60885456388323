import * as React from 'react'
import MediaQuery from 'react-responsive'
import { Link, NavLink } from 'react-router-dom'
import { routes } from '../../../config/routes'

interface IProps {}
interface IState {}

export class Header extends React.Component<IProps, IState> {
  render() {
    return (
      <div className="header">
        <div className="header-container">
          <div className="container">
            <div className="container">
              <header className="header">
                <Link to="/" className="logicbuilds-logo" title="Logicbuilds">
                  <span></span>
                </Link>
                <div className="menu">
                  <MediaQuery minWidth={768}>
                    {routes.map((route) => (
                      <NavLink
                        className={'inactive ' + route.className}
                        activeClassName="active"
                        key={route.key}
                        to={route.path}
                      >
                        {route.name}
                      </NavLink>
                    ))}
                  </MediaQuery>
                </div>
              </header>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
