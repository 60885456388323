import * as React from "react";
import { Layout, Hero } from "../../Common";
import { Link } from "react-router-dom";

// Image assets
import { ReactComponent as IconSinglePageAppsSVG } from "../../../images/Logicbuilds-Hero-Product-Single-Page-Apps.svg";
import { ReactComponent as SinglePageAppsModernSVG } from "../../../images/Logicbuilds-Hero-Product-Single-Page-Apps-Modern.svg";
import { ReactComponent as SinglePageAppsPWASVG } from "../../../images/Logicbuilds-Hero-Product-Single-Page-Apps-PWA.svg";
import { ReactComponent as SinglePageAppsScaleSVG } from "../../../images/Logicbuilds-Hero-Product-Single-Page-Apps-Scale.svg";

interface IProps {}
interface IState {}

export class SinglePageApps extends React.Component<IProps, IState> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="product online-store hero-container-padding-halved">
        <Layout {...this.props}>
          <Hero
            h1="Focalstate"
            body={[
              "Focalstate is our single page app framework that uses the latest web technologies to bring you a blazing-fast production grade React website.",
            ]}
            // buttonHashGroup={{
            //   primaryButtonHashLink: '#single-page-app-features',
            //   primaryButtonClassName: 'button info margin-right-1',
            //   primaryButtonText: 'Learn More',
            //   secondaryButtonRedirect: '/online-store-demo',
            //   secondaryButtonClassName: 'button primary',
            //   secondaryButtonText: 'View Demo',
            // }}
          >
            <IconSinglePageAppsSVG />
          </Hero>

          <section
            id="single-page-app-features"
            className="card-container has-header card-headings-have-colour card-product-feature"
          >
            <div className="container">
              <div className="card-header"></div>
              <section className="grid flex-direction-column-reverse">
                <div className="grid-item grid-item-tablet-6 text-align-center-mobile">
                  <h2>Modern web tech</h2>
                  <p className="font-weight-thin">
                    Enjoy the power of the latest web technologies – React.js,
                    Webpack, modern JavaScript and CSS and more — all set up and
                    waiting for you to start building.
                  </p>
                </div>
                <div className="grid-item grid-item-tablet-4 grid-item-offset-1 icon-product-feature">
                  <SinglePageAppsModernSVG />
                </div>
              </section>
              <section className="grid">
                <div className="grid-item grid-item-tablet-4 icon-product-feature text-align-center-mobile">
                  <SinglePageAppsPWASVG />
                </div>
                <div className="grid-item grid-item-tablet-6 grid-item-offset-1 text-align-center-mobile">
                  <h2>Progressive Web App</h2>
                  <p className="font-weight-thin">
                    Focalstate is a PWA (Progressive Web App) generator. Our
                    website solution loads only the critical HTML, CSS, data,
                    and JavaScript so your site loads as fast as possible.
                  </p>
                </div>
              </section>
              <section className="grid flex-direction-column-reverse">
                <div className="grid-item grid-item-tablet-6 text-align-center-mobile">
                  <h2>Scale to infinity</h2>
                  <p className="font-weight-thin">
                    Focalstate is Internet Scale. Forget complicated deploys
                    with databases and servers and their expensive,
                    time-consuming setup costs, maintenance, and scaling fears.
                    Focalstate builds your site as “static” files which can be
                    deployed easily on dozens of services.
                  </p>
                </div>
                <div className="grid-item grid-item-tablet-4 grid-item-offset-1 icon-product-feature">
                  <SinglePageAppsScaleSVG />
                </div>
              </section>
            </div>
          </section>
          <section className="card-container has-header call-to-action padding-top-1 colour-brand-white">
            <div className="container">
              <div className="card-header">
                <h2>Ready to speed past your competition?</h2>
                <p>See how Focalstate can power your next website.</p>
                <Link to="/contact">
                  <button className="button primary width-25vw">
                    Contact us for a free demo
                  </button>
                </Link>
              </div>
            </div>
          </section>
        </Layout>
      </div>
    );
  }
}
