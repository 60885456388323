import * as React from "react";
import { Layout } from "../../Common";
import { Link } from "react-router-dom";

import { Hero } from "../../Common";

// Image assets
import { ReactComponent as HomeHeroSVG } from "../../../images/Logicbuilds-Hero-Home.svg";
import { ReactComponent as IconDesign } from "../../../images/Icon-Design.svg";
import { ReactComponent as IconWeb } from "../../../images/Icon-Web.svg";
import { ReactComponent as IconLightning } from "../../../images/Icon-Lightning.svg";
import { ReactComponent as IconDeployments } from "../../../images/Icon-Deployments.svg";
import { ReactComponent as IconSecurity } from "../../../images/Icon-Security.svg";
import { ReactComponent as IconSearch } from "../../../images/Icon-Search.svg";

interface HomeProps {}
interface HomeState {}

export class Home extends React.Component<HomeProps, HomeState> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="home">
        <Layout {...this.props}>
          <Hero
            h1="Architects of Next Generation Website Solutions"
            body={[
              "Logicbuilds specialises in next-generation website development that delivers bespoke solutions that are fast, responsive and delightful.",
            ]}
            buttonGroup={{
              primaryButtonRedirect: "/products",
              primaryButtonClassName: "button primary margin-right-1",
              primaryButtonText: "View Products",
              secondaryButtonRedirect: "/contact",
              secondaryButtonClassName: "button info",
              secondaryButtonText: "Contact Us",
            }}
          >
            <HomeHeroSVG />
          </Hero>

          <section className="card-container">
            <div className="container">
              <div className="card-header">
                <h2>Our Process</h2>
                <p>
                  Our 3-stage process forms the basis of how we will build your
                  brand.
                </p>
              </div>
              <div className="card-container is-grouped grid grid-no-padding grid-no-wrap">
                <div className="card card-feature grid-item grid-item-laptop-3">
                  <div className="card-feature-content card-feature-process">
                    <h3>Brand</h3>
                    <p>
                      We establish a clear brand proposition and define an
                      effective strategy.
                    </p>
                  </div>
                </div>
                <div className="card card-feature grid-item grid-item-laptop-3">
                  <div className="card-feature-content card-feature-process">
                    <h3>Engage</h3>
                    <p>
                      We develop your brand proposition into a world-class,
                      performance-geared website.
                    </p>
                  </div>
                </div>
                <div className="card card-feature grid-item grid-item-laptop-3">
                  <div className="card-feature-content card-feature-process">
                    <h3>Growth</h3>
                    <p>
                      We grow your company further and explore new directions
                      for your company and brand.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="card-container padding-top-none">
            <div className="container">
              <div className="card-header">
                <h2>Our Features</h2>
                <p>These are features that form the basis of our service.</p>
              </div>
              <div className="card">
                <div className="grid grid-items-have-gap grid-no-padding margin-top-2 card-text-align-center">
                  <div className="grid-item grid-item-tablet-4">
                    <div className="icon-header">
                      <i>
                        <IconDesign />
                      </i>
                      <h3>Sleek design</h3>
                    </div>
                    <p>
                      We design no-nonsense websites that will perfectly match
                      with the look and feel of your brand.
                    </p>
                  </div>
                  <div className="grid-item grid-item-tablet-4">
                    <div className="icon-header">
                      <i>
                        <IconWeb />
                      </i>
                      <h3>Modern web tech</h3>
                    </div>
                    <p>
                      Enjoy the power of the latest web technologies. Every
                      website is a web app and every web app is a website.
                    </p>
                  </div>
                  <div className="grid-item grid-item-tablet-4">
                    <div className="icon-header">
                      <i>
                        <IconLightning />
                      </i>
                      <h3>Ultimate speed</h3>
                    </div>
                    <p>
                      We use state-of-the-art technology to make sure your
                      website is lightning fast and loads in the blink of an
                      eye.
                    </p>
                  </div>
                  <div className="grid-item grid-item-tablet-4">
                    <div className="icon-header">
                      <i>
                        <IconDeployments />
                      </i>
                      <h3>Effortless deployments</h3>
                    </div>
                    <p>
                      Our smart builds makes our deployments on average 2.5x
                      faster than other static frameworks.
                    </p>
                  </div>
                  <div className="grid-item grid-item-tablet-4">
                    <div className="icon-header">
                      <i>
                        <IconSecurity />
                      </i>
                      <h3>Security first</h3>
                    </div>
                    <p>
                      HTTPS is enabled by default and doesn't require extra
                      configuration. We auto-renew SSL certificates.
                    </p>
                  </div>
                  <div className="grid-item grid-item-tablet-4 card-feature">
                    <div className="icon-header">
                      <i>
                        <IconSearch />
                      </i>
                      <h3>Search friendly</h3>
                    </div>
                    <p>
                      Our well crafted applications delivers a better user
                      experience, which helps you win at SEO from the start.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="card-container has-header call-to-action">
            <div className="container">
              <div className="card-header">
                <h2>Ready to get an amazing website?</h2>
                <p>
                  Learn how Logicbuilds can help your business and brand today.
                </p>
                <Link to="/products">
                  <button className="button primary">View our Products</button>
                </Link>
              </div>
            </div>
          </section>
        </Layout>
      </div>
    );
  }
}
