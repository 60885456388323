import * as React from "react";
import { BrowserRouter as Router, Link, Switch, Route } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import MediaQuery from "react-responsive";

import { routes } from "../../config/routes";
import { NotFound } from "../../components/Pages/NotFound";
import { Header } from "../Common";
import Headroom from "react-headroom";

interface IState {
  menuOpen: boolean;
}

interface IProps extends React.Props<{}> {}

export class App extends React.Component<IProps, IState> {
  state = {
    menuOpen: false,
  };

  // This keeps your state in sync with the opening/closing of the menu
  // via the default means, e.g. clicking the X, pressing the ESC key etc.
  handleStateChange = (state: { isOpen: boolean }) => {
    this.setState({ menuOpen: state.isOpen });
  };

  // This can be used to close the menu, e.g. when a user clicks a menu item
  closeMenu = () => {
    this.setState({ menuOpen: false });
  };

  render() {
    return (
      <div id="outer-container" className="app">
        <Router>
          <div>
            <MediaQuery maxWidth={767}>
              <Menu
                isOpen={this.state.menuOpen}
                pageWrapId={"page-wrap"}
                outerContainerId={"outer-container"}
                right={true}
                onStateChange={(state) => this.handleStateChange(state)}
              >
                {routes.map((route) => (
                  <Link
                    key={route.key}
                    className={"menu-item " + route.className}
                    onClick={() => this.closeMenu()}
                    to={route.path}
                  >
                    {route.name}
                  </Link>
                ))}
              </Menu>
            </MediaQuery>
            <main id="page-wrap">
              {/* Refactor required here */}
              <MediaQuery maxWidth={767}>
                <Header />
              </MediaQuery>
              <MediaQuery minWidth={768}>
                <Headroom>
                  <Header />
                </Headroom>
              </MediaQuery>
              <Switch>
                {routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.path === "/"}
                    component={route.component}
                  />
                ))}
                <Route component={NotFound} />
              </Switch>
            </main>
          </div>
        </Router>
      </div>
    );
  }
}
