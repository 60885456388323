import * as React from "react";
import { Layout } from "../../Common";

import { Hero } from "../../Common";

// Image assets
import { ReactComponent as HeroAboutSVG } from "../../../images/Logicbuilds-Hero-About.svg";

interface IProps {}
interface IState {}

export class About extends React.Component<IProps, IState> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="about">
        <Layout {...this.props}>
          <Hero
            h1="About Logicbuilds"
            h2="What makes us who we are"
            body={[
              "Logicbuilds specialise in next-generation website development that delivers bespoke solutions that are fast, responsive and delightful.",
            ]}
            button={{
              redirect: "/products",
              className: "button primary",
              text: "View our Products",
            }}
          >
            <HeroAboutSVG />
          </Hero>
        </Layout>
      </div>
    );
  }
}
