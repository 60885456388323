import * as React from "react";
import { Layout } from "../../Common";
import { Hero } from "../../Common";

// Image assets
import { ReactComponent as ProductsHeroSVG } from "../../../images/Logicbuilds-Hero-Products.svg";
import { ReactComponent as OnlineStoreSVG } from "../../../images/Logicbuilds-Hero-Product-Online-Store.svg";
import { ReactComponent as IconSinglePageAppsSVG } from "../../../images/Logicbuilds-Hero-Product-Single-Page-Apps.svg";
import { ReactComponent as IconCMS } from "../../../images/Logicbuilds-Hero-Product-CMS.svg";

interface IProps extends React.Props<any> {
  handleHover?: any;
}
interface IState {
  isHovered: boolean;
}

export class Products extends React.Component<IProps, IState> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  constructor(props: IProps) {
    super(props);
    this.state = {
      isHovered: false,
    };
    this.handleHover = this.handleHover.bind(this);
  }

  handleHover() {
    this.setState((prevState) => ({
      isHovered: !prevState.isHovered,
    }));
  }

  render() {
    const handleHoverClass = this.state.isHovered
      ? "animated faster fadeIn"
      : "animated faster fadeOut";
    return (
      <div className="products">
        <Layout {...this.props}>
          <Hero
            h1="Our Products"
            body={[
              "We specialise in WooCommerce, Web and Mobile React development solutions.",
              "We transform your vision by using the latest cutting edge technologies in web development with unique and stunning designs.",
            ]}
          >
            <ProductsHeroSVG />
          </Hero>
          <section className="card-container card-negative-top-margin">
            <div className="container">
              <div className="card-container is-grouped grid grid-no-padding grid-no-wrap">
                <a href="/product/shopsense-online-store-ecommerce">
                  <div className="card card-feature grid-item grid-item-desktop-4">
                    <div className="card-feature-icon card-feature-icon-product">
                      <i className="icon-product">
                        <OnlineStoreSVG />
                      </i>
                    </div>
                    <div className="card-feature-content headings-have-colour">
                      <h3 className="padding-top-none">Shopsense</h3>
                      <span className="padding-bottom-1-halved">
                        eCommerce Platform
                      </span>
                      <p>
                        Our eCommerce platform is an AI powered, end-to-end
                        online storefront that is designed to set up and grow
                        your sales.
                      </p>
                    </div>
                  </div>
                </a>
                <a href="/product/focalstate-single-page-apps">
                  <div className="card card-feature grid-item grid-item-desktop-4">
                    <div className="card-feature-icon card-feature-icon-product">
                      <i className="icon-product">
                        <IconSinglePageAppsSVG />
                      </i>
                    </div>
                    <div className="card-feature-content headings-have-colour">
                      <h3 className="padding-top-none">Focalstate</h3>
                      <span className="padding-bottom-1-halved">
                        Single Page Applications
                      </span>
                      <p>
                        We build production grade React applications with a
                        blazing-fast framework that is used by some of the
                        world's largest brands.
                      </p>
                    </div>
                  </div>
                </a>
                <a href="/product/focalstate-pro-supercharged-apps">
                  <div className="card card-feature grid-item grid-item-desktop-4">
                    <div className="card-feature-icon card-feature-icon-product">
                      <i className="icon-product">
                        <IconCMS />
                      </i>
                    </div>
                    <div className="card-feature-content headings-have-colour">
                      <h3 className="padding-top-none">Focalstate Pro</h3>
                      <span className="padding-bottom-1-halved">
                        Supercharged Applications
                      </span>
                      <p>
                        Our production grade React application offering,
                        supercharged with easy design and content management.
                      </p>
                    </div>
                    <div
                      className={"coming-soon-container " + handleHoverClass}
                      onMouseEnter={this.handleHover}
                      onMouseLeave={this.handleHover}
                    >
                      {/* <div className="coming-soon-content">
                        <h3>Undefyned</h3>
                        <span className="padding-bottom-1-halved">
                          Static Site CMS
                        </span>
                        <p>Coming Soon</p>
                        <a href="/contact" className="button primary">
                          Get notified when
                        </a>
                      </div> */}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </section>
        </Layout>
      </div>
    );
  }
}
