import * as React from "react";
import { ReactComponent as LogicbuildsLogo } from "../../../images/Logicbuilds-Logo-Light.svg";
import { ReactComponent as IconTwitter } from "../../../images/Icon-Twitter.svg";
import { ReactComponent as IconFacebook } from "../../../images/Icon-Facebook.svg";
import { Link } from "react-router-dom";

interface IProps {}
interface IState {}

export class Footer extends React.Component<IProps, IState> {
  render() {
    return (
      <div className="footer">
        <div className="footer-container">
          <div className="container">
            <footer className="footer">
              <div className="logo-copy">
                <LogicbuildsLogo />
                <div className="copy">
                  <p>
                    &copy; {new Date().getFullYear()} Logicbuilds. All rights
                    reserved.
                  </p>
                </div>
                <p className="legal-privacy">
                  <a href="/terms">Terms and Conditions</a>
                  <span>|</span>
                  <a href="/privacy">Privacy Policy</a>
                </p>
              </div>
              <div className="menu-social">
                <div className="menu-footer">
                  <Link to="/about">
                    <span>About</span>
                  </Link>
                  <Link to="/products">
                    <span>Products</span>
                  </Link>
                  <Link to="/features">
                    <span>Features</span>
                  </Link>
                  <Link to="/contact">
                    <span>Contact</span>
                  </Link>
                </div>
                <div className="social-footer">
                  <p>
                    <a href="mailto:info@logicbuilds.co.za">
                      info@logicbuilds.co.za
                    </a>
                  </p>
                  <div className="social-link">
                    <i className="icon-size-16 icon-color-white icon-rounded-container">
                      <IconFacebook />
                    </i>{" "}
                    <a
                      href="https://facebook.com/logicbuilds"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span></span>
                    </a>
                  </div>
                  <div className="social-link">
                    <i className="icon-size-16 icon-color-white icon-rounded-container">
                      <IconTwitter />
                    </i>{" "}
                    <a
                      href="https://twitter.com/logicbuilds"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span></span>
                    </a>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}
