import React, { useState } from 'react'
import useForm from 'react-hook-form'
import * as emailjs from 'emailjs-com'

import { contactConfig } from '../../../config'
import { Input } from '../'

export const ContactForm = () => {
  const [isLoading, setLoading] = useState(false)
  const [mailSent, setMailSent] = useState(false)
  const [hasError, setHasError] = useState(false)

  const { register, handleSubmit, errors } = useForm()

  const onSubmit = async (data: any) => {
    try {
      setLoading(true)
      const { serviceID, templateID, form, userID } = contactConfig
      await emailjs.sendForm(serviceID, templateID, form, userID)
      setMailSent(true)
      setLoading(false)
    } catch (err) {
      setHasError(true)
    }
  }

  return (
    <div className="contact-form">
      {!mailSent && (
        <form id="logicbuilds-contact-form" onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="Your Name"
            name="name"
            type="text"
            errors={errors}
            register={register}
            required
          />
          <Input
            label="Your Email"
            name="email"
            type="email"
            errors={errors}
            register={register}
            required
          />
          <Input
            label="Your Message"
            name="message"
            type="textarea"
            errors={errors}
            register={register}
            required
          />
          <button className="button primary" type="submit" value="Send message">
            {isLoading ? 'Sending...' : 'Send message'}
          </button>
        </form>
      )}
      <div>
        {mailSent && (
          <div>
            <h2>Message sent</h2>
            <p>Thank you for contacting us.</p>
            <p>We will be in touch soon.</p>
          </div>
        )}
        {hasError && (
          <div className="alert alert-error">Email sending failed, Please try again.</div>
        )}
      </div>
    </div>
  )
}
