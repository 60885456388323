import * as React from 'react'
import { Layout } from '../../Common'
import { HeroSimple } from '../../Common'

interface IProps {}
interface IState {}

export class Terms extends React.Component<IProps, IState> {
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="features">
        <Layout {...this.props}>
          <HeroSimple h1="Terms and Conditions" />
          <section className="card-container card-negative-top-margin">
            <div className="container">
              <div className="card-container is-grouped one-column card-no-bottom-padding">
                <div className="card">
                  <h2>Summary</h2>

                  <p>
                    We will always do our best to fulfil your needs and meet your goals, but
                    sometimes it is best to have a few simple things written down so that we both
                    know what is what, who should do what and what happens if things go wrong. In
                    this contract you won't find complicated legal terms or large passages of
                    unreadable text. We have no desire to trick you into signing something that you
                    might later regret. We do want what's best for the safety of both parties, now
                    and in the future.
                  </p>

                  <p>So in short;</p>

                  <p>
                    You: You have the authority to enter into this contract on behalf of yourself,
                    your company or your organisation. You’ll give us everything we need to complete
                    the project as and when and in the format we need it. You’ll review our work,
                    provide feedback and approval in a timely manner too. Deadlines work two ways,
                    so you’ll also be bound by dates we set together. You also agree to stick to the
                    payment schedule set out at the end of this contract.
                  </p>

                  <p>
                    Us: We have the experience and ability to do everything we’ve agreed with you
                    and we’ll do it all in a professional and timely manner. We'll endeavour to meet
                    every deadline that’s set and on top of that we'll maintain the confidentiality
                    of everything you give us. Details of the works
                  </p>

                  <h2>Design</h2>

                  <p>
                    We create look-and-feel designs, and flexible layouts that adapt to the
                    capabilities of many devices and screen sizes. We create designs iteratively and
                    use predominantly HTML and CSS so we won’t waste time mocking up every template
                    as a static visual. We may use static visuals to indicate a look-and-feel
                    direction (colour, texture and typography).
                  </p>

                  <p>
                    You’ll have plenty of opportunities to review our work and provide feedback.
                    We'll share our progress with you via email. If, at any stage, you’re not happy
                    with the direction our work is taking, you’ll pay us in full for everything
                    we’ve produced until that point and cancel this contract.
                  </p>

                  <h2>Text Content</h2>

                  <p>
                    We’re not responsible for writing or inputting any text copy. If you’d like us
                    to write new content or input text for you, we can provide a separate estimate
                    for that.
                  </p>

                  <h2>Photography</h2>

                  <p>
                    You should supply graphic files in an editable, vector digital format. You
                    should supply photographs in a high resolution digital format. If you choose to
                    buy stock photographs, we can suggest stock libraries. If you’d like us to
                    search for photographs for you, we can provide a separate estimate for that.
                  </p>

                  <h2>HTML, CSS and JavaScript</h2>

                  <p>
                    We deliver web page types developed from HTML5 markup, CSS3 stylesheets for
                    styling and unobtrusive JavaScript for feature detection, polyfills and other
                    behaviours.
                  </p>

                  <h2>Browser testing</h2>

                  <p>
                    Browser testing no longer means attempting to make a website look the same in
                    browsers of different capabilities or on devices with different size screens. It
                    does mean ensuring that a person’s experience of a design should be appropriate
                    to the capabilities of a browser or device.
                  </p>

                  <h2>Desktop browser testing</h2>

                  <p>
                    We test our work in the latest versions of major desktop browsers including
                    those made by Apple (Safari), Google (Chrome), Microsoft (Internet Explorer) and
                    Mozilla Firefox. We won’t test in other older browsers unless you specify
                    otherwise. If you need an enhanced design for an older browser, we can provide a
                    separate estimate for that.
                  </p>

                  <h2>Mobile browser testing</h2>

                  <p>
                    Testing popular small-screen devices is essential in ensuring that a person’s
                    experience of a design is appropriate to the capabilities of the device they’re
                    using. We test our work in: iOS Safari, Google Chrome Android. We won’t test
                    Blackberry, Opera Mini/Mobile, Windows Phone or other mobile browsers. If you
                    need us to test using these, we can provide a separate estimate for that.
                  </p>

                  <h2>Technical support</h2>

                  <p>
                    We are not a website hosting company so we don’t offer support for website
                    hosting, email or other services relating to hosting. You may already have
                    professional hosting and you might even manage that hosting in-house; if you do,
                    great. If you don’t, we can help you set up an account for you at one of our
                    preferred hosting providers. We can set up your site on a server, plus any
                    statistics software such as Google Analytics and we can provide a separate
                    estimate for that. Then, the updates to, and management of that server will be
                    up to you.
                  </p>

                  <h2>Changes and revisions</h2>

                  <p>
                    We know from experience that fixed-price contracts are rarely beneficial to you,
                    as they often limit you to your earliest ideas. We don’t want to limit your
                    ability to change your mind. The price at the beginning of this contract is
                    based on the length of time we estimate we'll need to accomplish everything
                    you’ve told us you want to achieve, but we’re happy to be flexible. If you want
                    to change your mind or add anything new, that won’t be a problem as we'll
                    provide a separate estimate for that.
                  </p>

                  <h2>Legal stuff</h2>

                  <p>
                    We can’t guarantee that our work will be error-free and so we can’t be liable to
                    you or any third-party for damages, including lost profits, lost savings or
                    other incidental, consequential or special damages, even if you’ve advised us of
                    them. Finally, if any provision of this contract shall be unlawful, void, or for
                    any reason unenforceable, then that provision shall be deemed severable from
                    this contract and shall not affect the validity and enforceability of any
                    remaining provisions.
                  </p>

                  <h2>Copyrights</h2>

                  <p>
                    First, you guarantee that all elements of text, images or other artwork you
                    provide are either owned by your good selves, or that you have permission to use
                    them.
                  </p>

                  <p>
                    Then, when your final payment has cleared, copyright will be automatically
                    assigned as follows:
                  </p>

                  <p>
                    You’ll own the visual elements that we create for this project. We'll give you
                    source files and finished files and you should keep them somewhere safe as we
                    are not required to keep a copy. You own all elements of text, images and data
                    you provided, unless someone else owns them.
                  </p>

                  <p>
                    We'll own the unique combination of these elements that constitutes a complete
                    design and we'll license its use to you, exclusively and in perpetuity for this
                    project only, unless we agree otherwise.
                  </p>

                  <p>
                    We love to show off our work and share what we’ve learned with other people, so
                    we reserve the right, with your permission, to display and link to your project
                    as part of our portfolio and to write about it on websites, in magazine articles
                    and in books.
                  </p>

                  <h2>Payments</h2>

                  <p>
                    I'm sure you understand how important it is as a business that you pay the
                    invoices that we send you promptly. As I'm sure you’ll want to stay friends, you
                    agree to stick tight to the payment schedule outlined in your web design and
                    development proposal.
                  </p>

                  <h2>Small Print</h2>

                  <p>
                    Just like a parking ticket, you can’t transfer your contract to anyone else
                    without our permission. Your contract stays in place and need not be renewed. If
                    for some reason one part of this contract becomes invalid or unenforceable, the
                    remaining parts of it remain in place.
                  </p>

                  <p>
                    Although the language is simple, the intentions are serious and your contract is
                    a legal document under exclusive jurisdiction of South African courts.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </div>
    )
  }
}
