import * as React from 'react'

export interface HeroSimpleProps extends React.Props<{}> {
  h1: string
}

export const HeroSimple = ({ h1 }: HeroSimpleProps) => {
  return (
    <div className="hero-container">
      <div className="container">
        <section className="hero padding-bottom-none">
          <h1>{h1}</h1>
        </section>
      </div>
    </div>
  )
}
