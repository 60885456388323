import * as React from "react";
import { Layout } from "../../Common";
import { Hero } from "../../Common";

// Image assets
import { ReactComponent as FeaturesHeroSVG } from "../../../images/Logicbuilds-Hero-Features.svg";
import { ReactComponent as IconDesign } from "../../../images/Icon-Design.svg";
import { ReactComponent as IconWeb } from "../../../images/Icon-Web.svg";
import { ReactComponent as IconLightning } from "../../../images/Icon-Lightning.svg";
import { ReactComponent as IconDeployments } from "../../../images/Icon-Deployments.svg";
import { ReactComponent as IconSecurity } from "../../../images/Icon-Security.svg";
import { ReactComponent as IconSearch } from "../../../images/Icon-Search.svg";

interface IProps {}
interface IState {}

export class Features extends React.Component<IProps, IState> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="features">
        <Layout {...this.props}>
          <Hero
            h1="Our Features"
            body={[
              "We have a combined experience of over 40 years of web development and put all our expert knowledge into creating the best web solutions available today.",
            ]}
          >
            <FeaturesHeroSVG />
          </Hero>
          <section className="card-container card-negative-top-margin">
            <div className="container">
              <div className="card">
                <div className="grid grid-items-have-gap grid-no-padding margin-top-2 card-text-align-center">
                  <div className="grid-item grid-item-tablet-4">
                    <div className="icon-header">
                      <i>
                        <IconDesign />
                      </i>
                      <h3>Sleek design</h3>
                    </div>
                    <p>
                      We design no-nonsense websites that will perfectly match
                      with the look and feel of your brand.
                    </p>
                  </div>
                  <div className="grid-item grid-item-tablet-4">
                    <div className="icon-header">
                      <i>
                        <IconWeb />
                      </i>
                      <h3>Modern web tech</h3>
                    </div>
                    <p>
                      Enjoy the power of the latest web technologies. Every
                      website is a web app and every web app is a website.
                    </p>
                  </div>
                  <div className="grid-item grid-item-tablet-4">
                    <div className="icon-header">
                      <i>
                        <IconLightning />
                      </i>
                      <h3>Ultimate speed</h3>
                    </div>
                    <p>
                      We use state-of-the-art technology to make sure your
                      website is lightning fast and loads in the blink of an
                      eye.
                    </p>
                  </div>
                  <div className="grid-item grid-item-tablet-4">
                    <div className="icon-header">
                      <i>
                        <IconDeployments />
                      </i>
                      <h3>Effortless deployments</h3>
                    </div>
                    <p>
                      Our smart builds makes our deployments on average 2.5x
                      faster than other static frameworks.
                    </p>
                  </div>
                  <div className="grid-item grid-item-tablet-4">
                    <div className="icon-header">
                      <i>
                        <IconSecurity />
                      </i>
                      <h3>Security first</h3>
                    </div>
                    <p>
                      HTTPS is enabled by default and doesn't require extra
                      configuration. We auto-renew SSL certificates.
                    </p>
                  </div>
                  <div className="grid-item grid-item-tablet-4 card-feature">
                    <div className="icon-header">
                      <i>
                        <IconSearch />
                      </i>
                      <h3>Search friendly</h3>
                    </div>
                    <p>
                      Our well crafted applications delivers a better user
                      experience, which helps you win at SEO from the start.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </div>
    );
  }
}
