import * as React from "react";
import { Layout, Hero } from "../../Common";
import { Link } from "react-router-dom";

// Image assets
import { ReactComponent as IconFocalStatePro } from "../../../images/Logicbuilds-Hero-Product-CMS.svg";

import { ReactComponent as IconCMS } from "../../../images/Logicbuilds-Hero-Product-Focalstate-Content.svg";
import { ReactComponent as IconStaticCMS } from "../../../images/Logicbuilds-Hero-Product-Focalstate-Static-CMS.svg";
import { ReactComponent as IconIntegrated } from "../../../images/Logicbuilds-Hero-Product-Integrated.svg";

interface IProps {}
interface IState {}

export class FocalstatePro extends React.Component<IProps, IState> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="product online-store hero-container-padding-halved">
        <Layout {...this.props}>
          <Hero
            h1="Focalstate Pro"
            body={[
              "Focalstate Pro is our single page app framework that is supercharged with content management capabilities and our advanced page builder.",
            ]}
          >
            <IconFocalStatePro />
          </Hero>
          <section
            id="online-store-features"
            className="card-container has-header card-headings-have-colour card-product-feature"
          >
            <div className="container">
              <div className="card-header"></div>
              <section className="grid flex-direction-column-reverse">
                <div className="grid-item grid-item-tablet-6 text-align-center-mobile">
                  <h2>Modular CMS</h2>
                  <p className="font-weight-thin">
                    Focalstate Pro is a single-page React app powered with our
                    custom CMS. Create custom-styled previews, UI widgets, and
                    editor plugins or add backends to support different Git
                    platform APIs.
                  </p>
                </div>
                <div className="grid-item grid-item-tablet-4 grid-item-offset-1 icon-product-feature">
                  <IconCMS />
                </div>
              </section>
              <section className="grid flex-direction-column">
                <div className="grid-item grid-item-tablet-4 icon-product-feature">
                  <IconStaticCMS />
                </div>
                <div className="grid-item grid-item-tablet-6 grid-item-offset-1 text-align-center-mobile">
                  <h2>Static benefits with Content Management</h2>
                  <p className="font-weight-thin">
                    Get the speed, security, and scalability of a static site,
                    while still providing a convenient editing interface for
                    content.
                  </p>
                </div>
              </section>
              <section className="grid flex-direction-column-reverse">
                <div className="grid-item grid-item-tablet-6 text-align-center-mobile">
                  <h2>Integrated workflow</h2>
                  <p className="font-weight-thin">
                    Your content is stored in your Git repository, alongside
                    your code, for easier versioning and the option to handle
                    content updates directly in Git.
                  </p>
                </div>
                <div className="grid-item grid-item-tablet-4 grid-item-offset-1 icon-product-feature">
                  <IconIntegrated />
                </div>
              </section>
            </div>
          </section>
          <section className="card-container has-header call-to-action">
            <div className="container">
              <div className="card-header">
                <h2>Ready to speed past your competition?</h2>
                <p>See how Focalstate Pro can supercharge your next website.</p>
                <Link to="/contact">
                  <button className="button primary width-25vw">
                    Contact us for a free demo
                  </button>
                </Link>
              </div>
            </div>
          </section>
        </Layout>
      </div>
    );
  }
}
