import * as React from 'react';
import { Layout, HeroSimple } from '../../Common';

interface IProps { }
interface IState { }

export class Brand extends React.Component<IProps, IState> {

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div className="brand">
				<Layout {...this.props}>
					<HeroSimple
						h1="Brand Identity Guidelines"
					/>
					<section className="card-container card-negative-top-margin">
						<div className="container">
							<div className="card-container is-grouped one-column card-no-bottom-padding">
								<div className="card">
									{/* Brand Intro */}
									<div className="brand-section">
										<div className="brand-header">
											<h2>Brand Identity — The Way We See It</h2>
											<h3>Our ‘brand role’ is a simple and powerful statement that guides everything we do. Including how we look, think and talk to the world.</h3>
										</div>
										<div className="brand-content">
											<section className="grid grid-no-padding">
												<div className="grid-item grid-item-tablet-6">
													<h4>Why?</h4>
													<p>The beauty of working together is that everybody has their own ideas and perspectives. However, if we want to build something amazing together we have to agree on a solid foundation.</p>
												</div>
												<div className="grid-item grid-item-tablet-6">
													<h4>How?</h4>
													<p>The purpose of brand identity is to give you a solid foundation for your work, but it won’t solve every design question you’ll encounter with. Stay creative and feel free to think out of the box when necessary. </p>
												</div>
											</section>
										</div>
									</div>
									{/* Colours */}
									<div className="brand-section">
										<div className="brand-header">
											<h2>Colours</h2>
											<h3>We love colours. Mostly we use our two main colours, but we also have a wide variety of secondary colours as well.</h3>
										</div>
										<div className="brand-content">
											<section className="grid grid-no-padding">
												<div className="grid-item grid-item-mobile-12">
													<h4>Brand Colours</h4>
													<section className="grid grid-no-padding">
														<div className="colour-block-container"><div className="colour-block colour-brand-primary"></div><div className="colour-name">Brand Primary</div><div className="colour-hex"><code>#039be5</code></div></div>
														<div className="colour-block-container"><div className="colour-block colour-brand-light-blue"></div><div className="colour-name">Brand Light</div><div className="colour-hex"><code>#effaff</code></div></div>
														<div className="colour-block-container"><div className="colour-block colour-brand-paragraph-text-black"></div><div className="colour-name">Brand Text</div><div className="colour-hex"><code>#767d80</code></div></div>
														<div className="colour-block-container"><div className="colour-block colour-brand-heading-text-black"></div><div className="colour-name">Brand Gray</div><div className="colour-hex"><code>#43414e</code></div></div>
														<div className="colour-block-container"><div className="colour-block colour-brand-success"></div><div className="colour-name">Brand Success</div><div className="colour-hex"><code>#3ecf8e</code></div></div>
													</section>
												</div>
											</section>
										</div>
									</div>
									{/* Typography */}
									<div className="brand-section">
										<div className="brand-header">
											<h2>Typographic Style</h2>
											<h3>We are extremely serious about pushing our typographic experience to the next level.</h3>
										</div>
										<div className="brand-content">
											<section className="grid grid-items-have-gap grid-no-padding">
												<div className="grid-item grid-item-tablet-6">
													<small>Catamaran for headings</small>
													<span className="font-preview font-headings">AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz123456789!@#$%</span>
													<p>When we digged through the online world for the perfect typeface for our headings we wanted a typeface, which emphasize credibility, confidence and trust reflecting our deep underlying values. After days of searching we found Catamaran the single most versatile font with all the features we dreamed about.</p>
												</div>
												<div className="grid-item grid-item-tablet-6">
													<small>Open Sans for body texts</small>
													<span className="font-preview font-body">AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz123456789!@#$%</span>
													<p>For our body text we wanted to choose a typeface which is impossible to stop reading it. This is the point where we can grab that attention we get from using Open Sans. Thanks to the slightly curved shape of Open Sans it gives a friendly tone to our voice in the typographic world.</p>
												</div>
											</section>
										</div>
									</div>
									{/* The Way We Communicate */}
									<div className="brand-section">
										<div className="brand-header">
											<h2>The Way We Communicate</h2>
											<h3>Sometimes how you say something is more important than what you say</h3>
										</div>
										<div className="brand-content">
											<section className="grid grid-no-padding">
												<div className="grid-item grid-item-mobile-12">
													<h4>Personal</h4>
													<p>We love communicating on a personal level. This is the core of who we are and what we do.</p>
												</div>
												<div className="grid-item grid-item-mobile-12">
													<h4>Friendly</h4>
													<p>We’re your friends. We exist to help you manage through the difficulties of understanding the motivation of your customers.</p>
												</div>
												<div className="grid-item grid-item-mobile-12">
													<h4>Simple</h4>
													<p>We hate jargon. Our communication is clear, effortless and blissful.</p>
												</div>
											</section>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</Layout>
			</div>
		);
	}
}