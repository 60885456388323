import * as React from 'react'
import { Layout, Hero } from '../../Common'

// Image assets
import { ReactComponent as HeroNotFoundSVG } from '../../../images/Logicbuilds-Hero-NotFound.svg'

interface IProps {}
interface IState {
  pageTitle: string
  pageDescription: string
  pagePath: string
}

export class NotFound extends React.Component<IProps, IState> {
  state = {
    pageTitle: '404',
    pageDescription: 'Page not found',
    pagePath: window.location.href,
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div className="not-found">
        <Layout pageInfo={this.state} {...this.props}>
          <Hero
            h1="404 Page Not Found"
            body={[
              'Sorry, the page you are looking for does not exist.',
              'Please use the menu above the navigate the website.',
            ]}
            button={{
              redirect: '/',
              className: 'button primary',
              text: 'Go Home',
            }}
          >
            <HeroNotFoundSVG />
          </Hero>
        </Layout>
      </div>
    )
  }
}
