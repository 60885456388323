import * as React from "react";
import { Layout, Hero } from "../../Common";
import { Link } from "react-router-dom";

// Image assets
import { ReactComponent as OnlineStoreHeroSVG } from "../../../images/Logicbuilds-Hero-Product-Online-Store.svg";
import { ReactComponent as IconBuilder } from "../../../images/Icon-Builder.svg";
import { ReactComponent as IconAutocomplete } from "../../../images/Icon-Autocomplete.svg";
import { ReactComponent as IconNotification } from "../../../images/Icon-Notification.svg";
import { ReactComponent as IconOrder } from "../../../images/Icon-Order.svg";
import { ReactComponent as OnlineStoreSpeedSVG } from "../../../images/Logicbuilds-Hero-Product-Online-Store-Speed.svg";
import { ReactComponent as OnlineStoreConversionsSVG } from "../../../images/Logicbuilds-Hero-Product-Online-Store-Conversions.svg";
import { ReactComponent as OnlineStoreSEOSVG } from "../../../images/Logicbuilds-Hero-Product-Online-Store-SEO.svg";

interface IProps {}
interface IState {}

export class OnlineStore extends React.Component<IProps, IState> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="product online-store hero-container-padding-halved">
        <Layout {...this.props}>
          <Hero
            h1="Shopsense"
            body={[
              "Shopsense is our blazing-fast eCommerce solution that comes with a ton of features all designed to help you convert more users to customers.",
            ]}
          >
            <OnlineStoreHeroSVG />
          </Hero>
          <section
            id="online-store-features"
            className="card-container has-header card-headings-have-colour card-product-feature"
          >
            <div className="container">
              <div className="card-header"></div>
              <section className="grid flex-direction-column-reverse">
                <div className="grid-item grid-item-tablet-6 text-align-center-mobile">
                  <h2>The fastest eCommerce product</h2>
                  <p className="font-weight-thin">
                    Speed is essential — a delay of even milliseconds equals
                    lost orders. We've benchmarked Shoptimizer against the most
                    popular WooCommerce themes in the industry.
                  </p>
                </div>
                <div className="grid-item grid-item-tablet-4 grid-item-offset-1 icon-product-feature">
                  <OnlineStoreSpeedSVG />
                </div>
              </section>
              <section className="grid flex-direction-column">
                <div className="grid-item grid-item-tablet-4 icon-product-feature">
                  <OnlineStoreConversionsSVG />
                </div>
                <div className="grid-item grid-item-tablet-6 grid-item-offset-1 text-align-center-mobile">
                  <h2>Designed for conversions</h2>
                  <p className="font-weight-thin">
                    A conversion optimized focus for your store is important
                    because it can multiply your sales success without you
                    having to spend more time and money on traffic acquisition.
                  </p>
                </div>
              </section>
              <section className="grid flex-direction-column-reverse">
                <div className="grid-item grid-item-tablet-6 text-align-center-mobile">
                  <h2>SEO enhanced</h2>
                  <p className="font-weight-thin">
                    Organic search is most often the primary source of website
                    traffic. Choosing an online store solution that is optimized
                    for search engines is crucial for online businesses.
                  </p>
                </div>
                <div className="grid-item grid-item-tablet-4 grid-item-offset-1 icon-product-feature">
                  <OnlineStoreSEOSVG />
                </div>
              </section>
            </div>
          </section>
          <section className="card-container">
            <div className="container">
              <div className="card">
                <h2>Even more reasons to love Shopsense</h2>
                <p>
                  Besides design, speed and endless customizability, there are
                  many reasons to consider Shopsense.
                </p>
                <div className="grid grid-items-have-gap grid-no-padding margin-top-2">
                  <div className="grid-item grid-item-tablet-5 margin-bottom-1">
                    <div className="icon-header">
                      <i>
                        <IconBuilder />
                      </i>
                      <h3>Elementor page builder</h3>
                    </div>
                    <p>
                      Shopsense utilizes the very powerful Elementor page
                      builder for content pages - this lean and feature-packed
                      editor allows you to create sophisticated layouts with no
                      coding needed.
                    </p>
                  </div>
                  <div className="grid-item grid-item-tablet-5 grid-item-offset-1 margin-bottom-1">
                    <div className="icon-header">
                      <i>
                        <IconAutocomplete />
                      </i>
                      <h3>Auto-complete search</h3>
                    </div>
                    <p>
                      Searching for an item in Shopsense will reveal the top
                      results instantly without leaving the page. Convert users
                      quicker by leading them to the product they're looking
                      for.
                    </p>
                  </div>
                  <div className="grid-item grid-item-tablet-5">
                    <div className="icon-header">
                      <i>
                        <IconNotification />
                      </i>
                      <h3>Slide up sales notifications</h3>
                    </div>
                    <p>
                      Display recent orders on your site and increase
                      conversions by highlighting what other people are buying.
                      Display orders in real time and increase social proof.
                      Build a sense of urgency and expose new items to buy!
                    </p>
                  </div>
                  <div className="grid-item grid-item-tablet-5 grid-item-offset-1">
                    <div className="icon-header">
                      <i>
                        <IconOrder />
                      </i>
                      <h3>Complete an order quicker</h3>
                    </div>
                    <p>
                      Our order checkout page removes the header, footer and
                      sidebars, leaving the primary focal point of the checkout
                      — the payment details form. This distraction-free checkout
                      leads to better conversions.
                    </p>
                  </div>
                </div>
                <div className="product-section">
                  <div className="product-content">
                    <section className="grid grid-no-padding">
                      <div className="grid-item grid-item-tablet-5">
                        <h5>Why use Storesense</h5>
                        <p>
                          We've built Shopsense based upon extensive research
                          and best practices. We have analyzed the top eCommerce
                          sites on the web, and know that speed, design, and
                          conversions are key.
                        </p>
                      </div>
                      <div className="grid-item grid-item-tablet-5 grid-item-offset-1">
                        <h5>How does Storesense work</h5>
                        <p>
                          Shopsense is our eCommerce framework powered by
                          WordPress and WooCommerce. Because of our focus on
                          conversion based features, your users will become
                          customers in no time.
                        </p>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="card-container has-header call-to-action">
            <div className="container">
              <div className="card-header">
                <h2>Ready to get an amazing online store?</h2>
                <p>See how Shopsense can help your business and brand today.</p>
                <Link to="/contact">
                  <button className="button primary large width-25vw">
                    Contact us for a free demo
                  </button>
                </Link>
              </div>
            </div>
          </section>
        </Layout>
      </div>
    );
  }
}
