import { IRoutes } from "../models";

import {
  Home,
  About,
  Features,
  Products,
  Contact,
  Terms,
  Privacy,
  Brand,
  OnlineStore,
  FocalstatePro,
  SinglePageApps,
} from "../components/Pages";

export const routes: IRoutes[] = [
  {
    key: 1,
    name: "Home",
    path: "/",
    title: "Logicbuilds - Architects of Next Generation Website Solutions",
    description:
      "We specialise in next-generation website development that delivers bespoke solutions that are fast, responsive and delightful. Get more from your website.",
    component: Home,
    isNavRoute: true,
    className: "menu-item menu-item-home",
  },
  {
    key: 2,
    name: "About",
    path: "/about",
    title: "Logicbuilds - What makes us who we are",
    description:
      "Logicbuilds specialise in next-generation website development that delivers bespoke solutions that are fast, responsive and delightful.",
    component: About,
    isNavRoute: true,
    className: "menu-item",
  },
  {
    key: 3,
    name: "Products",
    path: "/products",
    title:
      "Logicbuilds specialises in WooCommerce, Web and Mobile React development solutions",
    description:
      "Logicbuilds specialises in WooCommerce, Web and Mobile React development solutions.",
    component: Products,
    isNavRoute: true,
    className: "menu-item",
  },
  {
    key: 4,
    name: "Features",
    path: "/features",
    title: "Logicbuilds Website Solutions Features",
    description:
      "Logicbuilds brings next-generation features to you with modern web tech, ultimate page speed and mobile first approach.",
    component: Features,
    isNavRoute: true,
    className: "menu-item",
  },
  {
    key: 5,
    name: "Contact",
    path: "/contact",
    title: "Contact Logicbuilds",
    description: "Contact Logicbuilds for your next website solution.",
    component: Contact,
    isNavRoute: true,
    className: "menu-item",
  },
  {
    key: 7,
    name: "Terms and Conditions",
    path: "/terms",
    title: "Terms and Conditions",
    description:
      "By using Logicbuilds services you are agreeing to the Terms of Service and all other clauses listed herein. Click here for the details.",
    component: Terms,
    isNavRoute: true,
    className: "menu-item menu-item-hide",
  },
  {
    key: 8,
    name: "Privacy Policy",
    path: "/privacy",
    title: "Privacy Policy",
    description:
      "This document is to explain how we handle data and information collected from you while working with Logicbuilds.",
    component: Privacy,
    isNavRoute: true,
    className: "menu-item menu-item-hide",
  },
  {
    key: 9,
    name: "Brand Guide",
    path: "/brand",
    title: "Logicbuilds Brand Identity Guidelines",
    description:
      "Logicbuilds brand identity guidelines defines how we can rock’n’roll together and always stay in sync",
    component: Brand,
    isNavRoute: true,
    className: "menu-item menu-item-hide",
  },
  {
    key: 10,
    name: "Online Store",
    path: "/product/shopsense-online-store-ecommerce",
    title:
      "Shopsense is our blazing-fast eCommerce solution for online stores.",
    description:
      "Shopsense is our blazing-fast eCommerce solution that comes with a ton of features all designed to help you convert more users to customers.",
    component: OnlineStore,
    isNavRoute: true,
    className: "menu-item menu-item-hide",
  },
  {
    key: 11,
    name: "Single Page Apps",
    path: "/product/focalstate-single-page-apps",
    title: "Logicbuilds Single Page Apps",
    description: "Logicbuilds Single Page Apps",
    component: SinglePageApps,
    isNavRoute: true,
    className: "menu-item menu-item-hide",
  },
  {
    key: 12,
    name: "Focalstate Pro",
    path: "/product/focalstate-pro-supercharged-apps",
    title: "Logicbuilds Single Page Apps Supercharged",
    description:
      "Logicbuilds Single Page Apps Supercharged with Focalstate Pro",
    component: FocalstatePro,
    isNavRoute: true,
    className: "menu-item menu-item-hide",
  },
];
