import * as React from "react";
import { Layout } from "../../Common";

import { Hero } from "../../Common";

// Image assets
import { ReactComponent as HeroContactSVG } from "../../../images/Logicbuilds-Hero-Contact.svg";
import { ReactComponent as IconTwitter } from "../../../images/Icon-Twitter.svg";
import { ReactComponent as IconFacebook } from "../../../images/Icon-Facebook.svg";
import { ContactForm } from "../../Common/ContactForm";

interface IProps {}

interface IState {}

export class Contact extends React.Component<IProps, IState> {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="contact">
        <Layout {...this.props}>
          <Hero
            h1="Contact Logicbuilds"
            body={[
              "Let’s talk about your website or project.",
              "Send us a message and we will be in touch within one business day.",
            ]}
          >
            <HeroContactSVG />
          </Hero>
          <section className="card-container card-negative-top-margin">
            <div className="container">
              <div className="card-container is-grouped one-column">
                <div className="card">
                  <section className="grid grid-no-padding">
                    <div className="grid-item grid-item-tablet-6 contact-form-container">
                      <ContactForm />
                    </div>
                    <div className="grid-item grid-item-tablet-4 contact-information">
                      <h3>Email</h3>
                      <p>
                        <a href="mailto:info@logicbuilds.co.za">
                          info@logicbuilds.co.za
                        </a>
                      </p>
                      <h3>Social Media</h3>
                      <div className="social-link">
                        <i className="icon-size-16 icon-color-white icon-rounded-container">
                          <IconFacebook />
                        </i>{" "}
                        <a
                          href="https://facebook.com/logicbuilds"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Facebook
                        </a>
                      </div>
                      <div className="social-link">
                        <i className="icon-size-16 icon-color-white icon-rounded-container">
                          <IconTwitter />
                        </i>{" "}
                        <a
                          href="https://twitter.com/logicbuilds"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Twitter
                        </a>
                      </div>
                      {/* <div className="social-link">
												<i className="icon-size-16 icon-color-white icon-rounded-container"><IconTwitter /></i> <a href="https://twitter.com/focalstate" target="_blank" rel="noopener noreferrer">LinkedIn</a>
											</div> */}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </div>
    );
  }
}
