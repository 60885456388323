import * as React from 'react';
import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
import { HashLink as LinkHash } from 'react-router-hash-link';

export interface HeroProps extends React.Props<{}> {
  containerHeight?: string;
  h1: string;
  h2?: string;
  body: string[];
  button?: {
    redirect: string;
    className: string;
    text: string;
  };
  buttonGroup?: {
    primaryButtonRedirect: string;
    primaryButtonClassName: string;
    primaryButtonText: string;
    secondaryButtonRedirect: string;
    secondaryButtonClassName: string;
    secondaryButtonText: string;
  };
  buttonHashGroup?: {
    primaryButtonHashLink: string;
    primaryButtonClassName: string;
    primaryButtonText: string;
    secondaryButtonRedirect: string;
    secondaryButtonClassName: string;
    secondaryButtonText: string;
  };
}

export const Hero = ({
  containerHeight,
  h1,
  h2,
  body,
  button,
  buttonGroup,
  buttonHashGroup,
  ...props
}: HeroProps) => {
  return (
    <div className={`hero-container ${containerHeight ? containerHeight : ''}`}>
      <div className='container has-hero-content'>
        <section className='hero justify-content-center'>
          <h1>{h1}</h1>
          {h2 && <h2>{h2}</h2>}
          {body.map((item) => (
            <p key={item}>{item}</p>
          ))}
          {button && (
            <Link to={button.redirect}>
              <button className={button.className}>{button.text}</button>
            </Link>
          )}
          {buttonGroup && (
            <div>
              <Link to={buttonGroup.primaryButtonRedirect}>
                <button className={buttonGroup.primaryButtonClassName}>
                  {buttonGroup.primaryButtonText}
                </button>
              </Link>
              <Link to={buttonGroup.secondaryButtonRedirect}>
                <button className={buttonGroup.secondaryButtonClassName}>
                  {buttonGroup.secondaryButtonText}
                </button>
              </Link>
            </div>
          )}
          {buttonHashGroup && (
            <div>
              <LinkHash smooth to={buttonHashGroup.primaryButtonHashLink}>
                <button className={buttonHashGroup.primaryButtonClassName}>
                  {buttonHashGroup.primaryButtonText}
                </button>
              </LinkHash>
              <Link to={buttonHashGroup.secondaryButtonRedirect}>
                <button className={buttonHashGroup.secondaryButtonClassName}>
                  {buttonHashGroup.secondaryButtonText}
                </button>
              </Link>
            </div>
          )}
        </section>
        <MediaQuery minWidth={1024}>
          <section className='hero-image'>{props.children}</section>
        </MediaQuery>
      </div>
    </div>
  );
};
